// import { Typography, Button, Box } from "@mui/material";
import React from 'react'




const HomePage =()=> {


  

    return (
        <div className="homePageContainer" />
          
      // </div>
    )
}

export default HomePage