import React from "react";
import { Typography } from "@mui/material";

const Footer = () => {
  return (
    <Typography sx={{ padding: "16px 60px", fontFamily: "Manrope" }}>
      {`Thank you for visiting the wedding website of Nikki Mogensen and Kai
      Bastos :)`}
    </Typography>
  );
};

export default Footer;
